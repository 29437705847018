/*
   statusbar-overlay sets the background color for the overlay. Black text is the default when the statusbar plugin is not added. When the
   statusbar plugin is added, it also adds default setting to make the content white via:

       <preference name="StatusBarStyle" value="lightcontent"/>

   Setting the background color to black will allow the default light content (white text/icons) to show on it. The statusbar plugin is included
   in the config.xml file and will be added by the CLI when you build locally.

   See https://github.com/apache/cordova-plugin-statusbar
*/


.statusbar-overlay {
    background: #000000;
}



html.with-statusbar.device-android .framework7-root {
  padding-top:0 !important;
}

#factory_brand {
  height:auto;
}

#factory_brand.list-block {
  margin:0px 0px;
}

.inlineBlock {
  display:inline-block !important;
}

#skiLookup , .skiLookupHomePageBtn, #registerBtn, .whiteTransBG {
  background-color: rgba(255, 255, 255, .6) !important;
}

#stockSkiListing_div.content-block-title {
  margin-top:11px;
}

.margin_top_1_5em {
  margin-top:1.5em;
}



#skiSelected {
  border: 1px solid #fff;
  border-radius: 0em;
  padding: .2em;
  background: #FFF;
  margin: .5em 0 0 0;
}

.homeLogo {
  width:20%;
  height:20%;
  margin-bottom: -20px;
}

.home_register_box {
  background-color: rgba(255,255,255,.6);
  border-radius: .5em;
  padding: .5em;
}

.ftSummaryList {
  display: inline-block;
  margin:1em 0 0 0;
}
/*
.navbar {
    background-color: rgba(255,255,255,.75)!important;
}
.navbar:after {
    height:0px!important;
}
*/

.nav_panel_finshare {
  font-family: serif;
  font-style: italic;
  font-size: 1.25em;
  font-weight: bold;
}

.head_setting_table {
  font-size: 1.0em;
  font-weight: normal;
  border-bottom: 1px solid #bbb;
  color: #000;
  padding: .3em 0em;
}

.blackText {
  color: #000;
}

.data_setting_table {
  font-size: 1.0em;
  padding: .3em 0em;
}

.sm {
  font-size: .95em;
  padding: .25em 0em;
  color: rgba(0, 122, 255, 0.85);
}

.padding_1em {
  padding:1em;
}

.padding_top_1em {
  padding-top:1em;
}

.padding_left_1em {
  padding-left:1em;
}

.padding_top_5em {
  padding-top:.5em;
}

.padding_bottom_5em {
  padding-bottom:.5em;
}

.padding_bottom_1_5em {
  padding-bottom:1.5em;
}

.padding_left_15px {
  padding-left: 15px;
}

.padding_left_0px {
  padding-left: 0px;
}

.padding_half_em {
  padding: .5em;
}

.black1_1_heading {
  font-size: 1.1em;
  font-weight: bold;
  color: #000;
}

.stockListRow {
  padding: .25em 0;
}

.white_glow_text {
    text-shadow: 0 0 3px #FFF;
}


#loginError {
    text-align: center;
    color: #d20f0f;
    font-weight: bold;
}


.redText {
    color: #F00 !important;
}

.darkGrayText1_1em {
    color: #333 !important;
    font-size: 1.1em;
}

#ul_saved_list {
  background-color: rgba(255,255,255,1);
}

#ul_saved_list li {
  padding-left:12px;
  border-bottom: 1px solid #EEE;
}

.alignLeft {
      text-align: left;
}

.alignRight {
      text-align: right;
}

.lightGreenBG {
  background-color: rgba(229,255,229,.8);
}

#getLocalSettings_div {
  display:none; /* don't show unless div has data */
}

.margin_1em_bottom {
  margin:0 0 1em 0;
}

.margin_1em_top {
  margin:1em 0 0 0;
}

.margin_1em {
  margin:1em;
}

.margin_2em_top {
  margin-top:2em;
}

.center {
  text-align: center;
}

.margin-left_1em {
  margin-left: 1em !important;
}

.margin-right_1em {
  margin-right: 1em !important;
}

.margin-right_qarter_em {
  margin-right: .25em !important;
}

.lightBlueBG {
background-color: rgba(33, 150, 243,.1);
}

.measure_table {
  font-size: 1.1em;
  padding: .2em 0em;
  border-bottom:1px solid #333;
  color:#333;
}

.override_lb .content-block {
    margin:34px 0 15px 0 !important;
}



.override_lb .list {
    margin:24px 0 15px 0 !important;
}

.marginBottomMinusHalf {
  margin-bottom: -.5em;
}

#mySettings_div .page-content>.list, #finShareDetails_div .page-content>.list {
  margin:0px 0px !important;
}

#mySettings_div #settingsList {
  margin-bottom:0px !important;
}

.centerList {
  width:100%;
  text-align: left;
    list-style-position: inside;
    -webkit-margin-before: 0px;
    -webkit-padding-start: 0px;
  padding:.3em .5em;
}

#loginForm .list ul {
  background-color: rgba(255, 255, 255,.6);
  border-radius: .5em;
}

#notifications .list {
  margin:0px !important;
}

#notifications .list ul {
  background-color: rgba(255, 255, 255,.7) !important;
}

#loginForm .list .item-floating-label {
  color:#007aff;
}

.bold {
  font-weight: bold;
}

.nowrap {
  white-space:nowrap;
}

.size-20 { font-size: 20px !important}

.orange {color: rgba(255, 132, 0, .6);}

.orange_bright {color: rgba(255, 132, 0, 1);}

.top_padding_5 {
  padding: 0 0 .5em 0;
}

.picker-modal-inner .content-block {
  margin-top:17px;
}

#measure_sheet {
  background-color: rgb(251, 240, 228);
  /*background-image: url(../img/water_bg.jpg);*/
}

#measure_sheet .toolbar {
  background-color: rgba(255, 200, 143, 1);
}

.page {
  background-image: url(../static/img/water_bg.jpg) !important;
}

#loginPage {
  background-image: url(../static/img/homePageImg1_60_opacity.jpg) !important;
  background-size: cover;
  display:flex;
}

.hp_container {
  margin:auto;
  height:90vh;
  width:85%;
}

#loginPage .page-content {
  background-color: rgba(255,255,255,0) !important;
  display: flex;
  align-content: space-between;
  justify-content: space-between;
  flex-direction: column;
}

#viewHistoryDiv {
  margin-top: 1.2em !important;
}

.page-content {
  background-color: rgba(255,255,255,.3) !important;
  padding-bottom:1em;
}

.popover-inner .content-block {
  margin: 1px 10px !important;
}
.popup .page-content {
  background-color: rgba(255,255,255,1) !important;
  overflow: scroll;
}

#profilePage_div .item-media i {
 /*size-20 color-blue*/
  font-size: 20px;
  color:#007aff;
 }

/* #editProfileBtn {
   color:#FFF;
 } */

#homeDetails .content-block {
  margin:11px 10px !important;
}

 .page .content-block-title {
   font-weight: bold;
   font-size: 1.25em;
   margin-top:15px;
 }

.page .content-block-title .rightIcon {
  float: right;
  padding-bottom: .15em;
  font-weight: normal;
}

.page .content-block-title .rightText {
  float: right;
  font-weight: normal;
  font-size: initial;
}

.vertAlignMiddle {
  vertical-align: middle;
}



.clear {
  clear:both;
}


#logoutBtn {
  display: inline-block;
  float: right;

}

/* DATA TABLES */
.data-table {
  overflow-x: auto;
}

.data-table table {
    width: 100%;
    border: none;
    padding: 0;
    margin: 0;
    border-collapse: collapse;
    text-align: left;
}

.data-table thead {
    font-size: 12px;
    border-bottom: 1px solid #8e8e93;
}

thead {
    display: table-header-group;
    vertical-align: middle;
    border-color: inherit;
}


.data-table thead td:not(.sortable-active), .data-table thead th:not(.sortable-active) {
    color: #8e8e93;
}

#stockSettings .data-table th.numeric-cell {
  font-weight:normal;
  font-size: 1em;
}

.data-table th.numeric-cell {
    text-align: right;
    padding-left: 0px !important;
}


.data-table td, .data-table th {
    padding: 0;
    position: relative;
    padding-left: 0px;
    padding-right: 15px;
    height: 2.0em;
}

#ulHistory .data-table td, #ulHistory .data-table th {
  height: 1.5em;
}

.data-table td.numeric-cell, .data-table th.numeric-cell {
    text-align: right;
    padding-left: 0px !important;
    color:#444 !important;
}

#addNoteSettingsTable .data-table td, #addNoteSettingsTable .data-table .numeric-cell {
  height:.3em !important;
  padding-left: 0px !important;
}

#stockSettings {
    background-color: rgba(255, 255, 255, 0.6);
    padding:0 0 .25em 0;
    border-bottom: 1px solid #666;
}
#stockSettings .stockName {
  color: #444;
  font-weight: bold;
  font-size: .8em;
}

#stockSettings .data-table th, #stockSettings .data-table td {
  height:1em !important;
  color: #007aff;
  font-size: .8em;
}

#stockSettings .data-table table {
  width:96% !important;
  margin:0 2%;
}

#stockSettingsDiv {
  display:block;
  /*height:0px;*/
  height:auto;
  opacity:0;
}

#viewStockBtn {
  font-size:.8em;
}

.notesPopUpHistory {
  color: #8e8e93;
  font-size: 12px;
  border-bottom: 1px solid #8e8e93;
  color: #8e8e93;
  font-weight: bold;
  margin: .8em 0 0 0;
}

.bottomGrayBorder {
  border-bottom:1px solid #ddd;
}
.notesPopUpDetails {
  margin: 0 0 1.2em 0;
}

.closePopup {
  float: right;
  padding: 1em;
}

.smallText {
  font-size: .9em;
  padding-left: 1.2em;
}

.text_8em {
  font-size: .8em;
}

/* ALTER SLIDER ON MY SETTINGS PAGE */
#settingsAdjuster .range-slider {
  background-size: 100% 9px !important;
  background: linear-gradient(to right,rgba(255, 132, 0, .6) 0,rgba(0, 122, 255, .6) 100%)!important;
  height:34px !important;
  border-radius: 18px !important;
}

#settingsAdjuster .overFlowHidden .item-input {
  width:99% !important;
  margin-top:0 !important;

}

/* turn off range bar on all sliders */
#settingsAdjuster .range-bar {
  display:none;
}
.androidSliderRangeKnob {
  width: 22px !important;
  height: 22px !important;
  left: -5px !important;
  top: -5px !important;
}

.stockText {
  color:#999;
  line-height: .9em;
  font-size: .85em;
  margin-left:auto;
}
.slideAdjust {
  display:none;
}

.createdDateText {
  display: inline-block;
  font-weight: normal;
  color: #888;
  text-overflow: ellipsis;
  max-width: 40%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: -.3em;
}

/*
.lightOrangeBG {
  background-color: rgba(255, 132, 0, .2) !important;
  border-bottom:1px solid #555;
}
*/
.lightOrangeBG {
background: -moz-linear-gradient(top, rgba(255, 132, 0, .1) 0%, rgba(255, 132, 0, .5) 100%); /* FF3.6-15 */
background: -webkit-linear-gradient(top, rgba(255, 132, 0, .1) 0%, rgba(255, 132, 0, .5) 100%); /* Chrome10-25,Safari5.1-6 */
background: linear-gradient(to bottom, rgba(255, 132, 0, .1) 0%, rgba(255, 132, 0, .5) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f2c39d', endColorstr='#fff1e4',GradientType=0 ); /* IE6-9 */
padding-left: .5em;
border-radius: 0em;
}

.floatLeft {
  float:left;
  margin-right: 1.0em;
}

.features-sheet {
  background-color: rgba(159,211,255, .9);
  font-size: 1em;
}


.settings_default {
  display:flex;
  align-items:baseline;
  flex: 0 0 100%;
}

.settings_default>div {
  font-size: 2em;
  padding-right:.2em;
}

.settings_edit {
  display:inline-block;
  align-items:center;
}

.settings_edit {
  font-size:1em;
}




#settingsAdjuster .item-content .item-inner .floatLeft {
  float:left;
  margin-right:.4em;
  width:4.8em
}

.floatRight {
  float:right;
  margin-left: 1.0em;
}

.textAlignRight {
  text-align: right;
}

.overFlowHidden {
  overflow: hidden;
  width: 95%;
}

.checkedRadio {
  font-weight: bold;
}

.measureOverlay {
  position: absolute;
  top: 0;
  left: 0;
  font-size:1.1em;
  color:#007aff;
  background-color: rgba(249, 181, 124, .7);;
  display:table;
  border-radius: .7em;
  /* border: 1px solid rgb(119 176 248);
  border-collapse: collapse; */
  padding: 0 .5em 0 .5em;
  margin: .1em 0 0 1.25em;
}

.content-block {
  margin:18px 0 !important;
}

.padding_l_r_15px {
  padding: 0 15px;
}

.padding_l_r_1em {
  padding: 0 1em !important;
}

.padding_l_r_4em {
  padding: 0 4em !important;
}

#mySettings_div #ul_mySki {
}

.ios #mySettings_div #ul_mySki {
  border-bottom: 1px solid #555;
}


#mySettings_div #settingsAdjuster {
  margin: -14px 0 0 0 !important;
}

.ios #mySettings_div #settingsAdjuster {
  border-top: 1px solid #555;
  border-bottom: 1px solid #555;
}

.list {
  margin:18px 0 !important;
}

.chevronB {
  color: #b6b6bb;
}

.chevronC {
  color: #d2d2e6;
}

.panel.panel-left .content-block {
  padding-left: 1em;
}

#userNameInPanel {
  text-transform: capitalize;
}

.panel.panel-left .navbar {
  background-color: #007aff !important;
  padding: 1em;
  color: #000;
  font-size: 18px;
  font-weight: bold;
}

.panel-left .view .page {
  background-color: #eee !important;
  background-image: none !important;
}

.menu_head .list {
  margin:0px !important;
}

.menu_items {
  margin-top: 2.2em;
  font-size: 17px;
  font-weight: 600;
  color: #007aff;
}

.flushBottom {
  position: absolute;
  bottom: 0;
  padding: .5em;
  /* height: 1.5em;
  width: 100%; */
}

#myLE_POS {
  position: absolute;
  top: 65%;
  left: 6%;
}

#myBindToLE_POS {
  position: absolute;
  top: 65%;
  left: 23%;
}

#summary_POS {
  position: absolute;
  top: 65%;
  left: 47%;
  line-height: 1.2em;
  color: #007aff;
  display:none;
}

#binding_POS {
  position: absolute;
  top: 5%;
  left: 60%;
  line-height: 1.2em;
  color: #007aff;
  font-weight: bold;
  display:inline-block;
}

/* #myBindingsDiv {
  display:none;
  padding:.25em;
  text-align: center;
  color:#666;
} */
.newLeadinEdgeInfo {
  line-height: .5em;
  color: #007aff;
}

.lightBlueText {
  color: #007aff;
}

.item-media #editSkiBtn {
  padding-top:.3em;
}

#editSkiBtn i {
  font-size:1.2em;
  margin-top: -5px;
  color:inherit;
  height: 100%;
}

.view_history i {
  font-size:1.2em;
  margin-top: -5px;
}

.historyNotes {
  border-bottom: 1px solid #999;
  padding: .3em .3em .6em .3em;
  margin: .3em;
}
.historyNotes:last-child {
  border-bottom: none;
}

.historyNotesHdr {
    /* background-color: rgba(0,0,0,.07); */
    /* border-bottom: 1px solid #aaa; */
    padding: .3em;
    font-weight: bold;
    font-size: 1em;
    color: #333;
}

.historyNotesHdr_old {
  background-color: rgba(0,0,0,.07);
  border-bottom:1px solid #aaa;
  padding:.3em;
}

.popup-notes>.page-content>.block {
  margin-top:1px !important;
}

#LE_def_POS {
  position: absolute;
  top:10%;
  left:6%;
}

#BLE_def_POS {
  position: absolute;
  top:10%;
  left:20%;
}

#skiCount {
  border-top:1px solid #aaa;
  /* margin-right: 1em; */
  padding: .5em;
  color: #666;
  clear:both;
}

.txtAreaHeight {
  height:fit-content !important;
  padding:.3em 0 !important;
}

#ski_card {
  border:1px solid #555;
  border-radius: 0em;
  box-shadow: 0px 2px 4px rgba(0,0,0,.5);
}
#ski_card .card-content-inner {
  padding: .5em;
}

.logo_36height {
  min-height: 30px;
  max-height: 40px;
  max-width: 50px;
  min-width: 30px;
}

#ul_mySki .item-content .item-inner {
  margin-left: 7px;
}

#ul_mySki .item-content .item-inner a {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}


#reg_ul li .item-media {
  padding-bottom:14px;
}

#tc_toggle > .item-input-wrap, #tc_toggle > .item-after  {
  display: inline-block;
  width: initial;
  padding: .6em .5em .7em 0em;
}

.hide {
  display:none !important;
}

#update_msg {
  font-size: .8em;
  border: 1px solid #f54336;
  padding: .3em;
  display: none;
  background-color: #f54336;
  border-radius: .3em;
  text-align: center;
  margin:.5em;
}

.showMySettingNotes {
}

#versDetails {
  color:#FFF;
}

#versDetails i {
  font-size:1.2em
}


#thisSettingNotes {
background-color: rgba(255,255,255,.6);
/*display:none;*/
width: 100%;
overflow: scroll;
}

.notesGradientBG {
background: -moz-linear-gradient(top, rgba(140,159,181,0.5) 0%, rgba(255,255,255,1) 28%, rgba(255,255,255,1) 72%, rgba(140,159,181,0.5) 100%); /* FF3.6-15 */
background: -webkit-linear-gradient(top, rgba(140,159,181,0.5) 0%,rgba(255,255,255,1) 28%,rgba(255,255,255,1) 72%,rgba(140,159,181,0.5) 100%); /* Chrome10-25,Safari5.1-6 */
background: linear-gradient(to bottom, rgba(0, 122, 255, 0.3) 0%,rgba(255,255,255,1) 35%,rgba(255,255,255,1) 65%,rgba(0, 122, 255, 0.3) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */

filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#80007aff', endColorstr='#80007aff',GradientType=0 ); /* IE6-9 */
}

.heightAuto {
  height:auto;
}

.heightZero {
  height:0px;
}

.settingNotesView {
  display:inline-block;
  height:0px;
  border-top: 1px solid #007aff;
  border-bottom: 1px solid #007aff;
  background: -moz-linear-gradient(top, rgba(140,159,181,0.5) 0%, rgba(255,255,255,1) 28%, rgba(255,255,255,1) 72%, rgba(140,159,181,0.5) 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(top, rgba(140,159,181,0.5) 0%,rgba(255,255,255,1) 28%,rgba(255,255,255,1) 72%,rgba(140,159,181,0.5) 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, rgba(140,159,181,0.5) 0%,rgba(255,255,255,1) 28%,rgba(255,255,255,1) 72%,rgba(140,159,181,0.5) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#80007aff', endColorstr='#80007aff',GradientType=0 ); /* IE6-9 */
}


#finShareDetails_div>.page-content, #finShareFollowers_div>.page-content, #finShare_div>.page-content, #finShareOverview_div>.page-content {
  background-color: rgba(255,255,255,.5) !important;
  padding-bottom: 0em;
}

#finShare_div .data-table {
  background-color: rgba(255,255,255,.7);
  padding-left: .5em;
  padding-right: .5em;
}


#fsUpperLeft {
  display:inline-block;
  float:left;
}

#fsUpperRight {
  display:inline-block;
  float:right;
  border:1px solid #000;
  padding:0em;
  text-align: center;
  background-color: #FFF;
}

.font_125em {
  font-size:1.25em !important;
}

.italics {
  font-style: italic;
}

.no-list-style-type {
  list-style-type: none;
}

.fs_name {
  font-size:1.25em;
  font-style: italic;
  text-decoration: underline;
}

.fs_binding {
  list-style-type: circle;
  margin-left: 1em;
  padding-left: 0;
  margin-top: .2em;
}

ul.fs_binding {
  list-style: none;
}

ul.fs_binding li::before {
  content: "\2022";
  color: var(--f7-theme-color);
  font-weight: bold;
  display: inline-block;
  width: 1em;
  font-size: 1.2em;
  margin-left: -1em;
}

#fs_seg_compare {
  background: rgba(255,255,255,.85);
}

.super-script {
  vertical-align: super;
}

.sa-msg {
  background-color:rgba(0,0,0,.7);
  color:#FFF;
  font-size: .85em;
  font-family: arial, helvetica, sans-serif;;
  text-align: center;
  padding:.2em;
}
.abilityChart {
  list-style-type: none;
  color:#888;
  padding:0;
  margin:0em;
  /* border-bottom:1px solid #888; */
}

.boxHeader {
  margin:0;
  text-align: center;
  background-color: #666;
  color: #FFF;
  width:100%;
  border-bottom:1px solid #000;
}

.boxContent {
  padding:.2em 1em;
}

#fs_leadingEdgeDiv {
  display:none;
  text-align: center;
  margin-bottom: 1em;
  margin-top:1em;
}

/* #fs_leadingEdgeDiv div {
  margin: .25em .5em;
  padding:.5em;
} */

#fs_SettingNotes {
background-color: rgba(255,255,255,.75);
width: 100%;
overflow: scroll;
}

#fs_settingsList {
  display:none;
  border-top: 1px solid #000;
  border-bottom: 1px solid;
}

#fs_settings_hdr {
  display:none;
}

.icon_addFinshare {
  color: var(--f7-theme-color);
}

.finShareRow td:last-child {
  padding-right:0;
}


#searchResults .list {
  margin:0 !important;
}
 #finShareAdd_div .page-content {
   background-color: rgba(255,255,255,.7) !important;
 }

.fs-req-count {
  position: relative;
  top: -5px;
  left: 3px;
  font-family: arial, helvetica, sans-serif;
}

.fs_search_radio {
  padding:.7em 1.2em;
}

.fs_search_block {
  background-color: #FFF !important;
}

.fs_mysettings {
  font-size:.7em;
  color: var(--f7-theme-color);
}

#fs_compare_link {
  display:inline-block;
}

.flex-container {
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: flex-start;
}

.flex-child {
    flex: 1;
}

.flex-child:first-child {
    margin-right: .7em;
    flex-grow:2
}

.font_2em {
  font-size: 2em !important;
}

.font__75em {
  font-size: .75em !important;
}

.font_5em {
  font-size: 5em !important;
}

.margin_bottom_minus8 {
  margin-bottom: 8px !important;
}

.height_1_5em {
  min-height: 1.5em !important;
}
